const SEO = [
	{
		page: "home",
		description:
			"Mamminasa Tech - Jasa Profesional Website & Mobile Development, UI/UX Design di Makassar. Mamminasa Tech menyediakan jasa profesional pengembangan website, aplikasi mobile, dan desain UI/UX di Makassar, Sulawesi Selatan. Kami menawarkan solusi digital inovatif dan efisien untuk bisnis Anda. Percayakan kebutuhan teknologi Anda kepada Mamminasa Tech untuk transformasi digital yang mengesankan.",
		keywords: [
			"Mamminasa",
			"Mamminasata",
			"Jasa pengembangan website Makassar",
			"Jasa pengembangan aplikasi mobile Makassar",
			"Desain UI/UX Makassar",
			"Pengembangan website profesional",
			"Pengembangan aplikasi mobile Sulawesi Selatan",
			"Layanan desain UI/UX Sulawesi Selatan",
			"Solusi digital Makassar",
			"Pengembangan aplikasi mobile canggih",
			"Desain UI/UX menarik",
			"Jasa IT profesional Makassar",
		],
	},

	{
		page: "products",
		description:
			"Temukan produk digital terbaik di Mamminsatech. Unduh aplikasi di Play Store dan App Store. Kami juga menawarkan jasa pengembangan website dan aplikasi mobile di Makassar, Sulawesi Selatan. Dapatkan solusi digital terbaik untuk kebutuhan Anda.",
		keywords: [
			"Mamminasa",
			"Mamminasata",
			"MamminasaTech",
			"Mamminasa Tech",
			"Produk digital terbaik",
			"Jasa pengembangan website",
			"Jasa pengembangan aplikasi mobile",
			"Pengembangan website Makassar",
			"Pengembangan aplikasi mobile Makassar",
			"Layanan IT Sulawesi Selatan",
			"Teknologi digital terkini",
			"Unduh aplikasi",
			"Download di Play Store",
			"Download di App Store",
		],
	},
	{
		page: "services",
		description:
			"Layanan Pembuatan Aplikasi Mobile, Website, dan UI/UX Profesional di Makassar - Mamminsatech. Mamminsatech menawarkan layanan pembuatan aplikasi mobile (Android/iOS), pengembangan website, dan desain UI/UX profesional di Indonesia, khususnya di Makassar, Sulawesi Selatan. Kami menyediakan solusi digital yang inovatif dan efisien untuk membantu bisnis Anda tumbuh dan bersaing di era digital. Dengan tim ahli yang berpengalaman, kami memastikan setiap proyek memenuhi standar kualitas tertinggi dan memberikan pengalaman pengguna yang luar biasa.",
		keywords: [
			"Jasa pembuatan aplikasi mobile Makassar",
			"Pengembangan aplikasi Android",
			"Pengembangan aplikasi iOS",
			"Jasa pengembangan website Makassar",
			"Jasa pembuatan website profesional",
			"Desain UI/UX Makassar",
			"Layanan UI/UX Sulawesi Selatan",
			"Pengembangan aplikasi mobile Makassar",
			"Solusi digital inovatif Makassar",
			"Jasa IT profesional Indonesia",
		],
	},

	{
		page: "about",
		description:
			"I am a backend developer with expertise in Node.js. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies.",
		keywords: ["Tharindu", "Tharindu N", "Tharindu Nayanajith"],
	},

	{
		page: "articles",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
		keywords: ["Tharindu", "Tharindu N", "Tharindu Nayanajith"],
	},

	{
		page: "projects",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to.",
		keywords: ["Tharindu", "Tharindu N", "Tharindu Nayanajith"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["Tharindu", "Tharindu N", "Tharindu Nayanajith"],
	},

	{
		page: "one-pdf",
		title: "One PDF | The Best PDF Reader App with a Simple and Easy-to-Use Design",
		description:
			"Download One PDF, the best mobile PDF reader app. Enjoy the ease of reading PDFs with a simple and user-friendly design. One PDF is the ideal solution for all your PDF reading needs.",
		keywords: [
			"One PDF",
			"OnePdf",
			"pdf reader",
			"Produk mamminasa tech",
			"Mamminasa Tech",
			"Pengembangan aplikasi mobile Makassar",
			"Jasa pengembangan website Makassar",
		],
	},
	
];

export default SEO;
