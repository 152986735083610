import React from "react";
import styled from "styled-components";
import { faPaintRoller, faSuitcase, faTrophy, faUniversalAccess } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OnePdfFeature from "../../../../assets/img/onepdf-feature.png";


const Feature = () => {
  return (
    <Wrapper id="feature">
      <div className="whiteBg">
        <div className="container" style={{ padding: "50px 0" }}>
          <Title className="font40 extraBold">Feature</Title>
          <Subtitle className="font18">One PDF has features that can help to open PDF files easily</Subtitle>
        </div>
        <Container className="row" >
          <LeftSide>
            <Item>
              <FontAwesomeIcon color="#FF6666" size="3x" icon={faPaintRoller} />
              <Fitur>
                <p className="font18 extraBold">Simple and Elegant Design</p>
                <p className="font15">One PDF features a minimalist yet elegant interface, making it easy to use
                  <br/>for everyone, including new users.
                </p>
              </Fitur>
            </Item>
            <Item>
              <FontAwesomeIcon color="#FF6666" size="3x" icon={faSuitcase} />
              <Fitur>
                <p className="font18 extraBold">Easy Navigation</p>
                <p className="font15">With intuitive navigation features, you can easily switch pages,
                  <br/>zoom in on text, and quickly access other features.
                </p>
              </Fitur>
            </Item>
            <Item>
              <FontAwesomeIcon color="#FF6666" size="3x" icon={faTrophy} />
              <Fitur>
                <p className="font18 extraBold">Fast and Stable Performance</p>
                <p className="font15">One PDF is designed to provide fast and stable performance,
                  <br/>allowing you to open and read PDF documents without any hassle.
                </p>
              </Fitur>
            </Item>
            <Item>
              <FontAwesomeIcon color="#FF6666" size="3x" icon={faUniversalAccess} />
              <Fitur>
                <p className="font18 extraBold">Wide Compatibility</p>
                <p className="font15">This app is compatible with various PDF formats and
                  <br/>can be opened on a wide range of mobile devices.
                </p>
              </Fitur>
            </Item>
          </LeftSide>
          <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={OnePdfFeature} alt="office" style={{zIndex: 9}} />
        </ImageWrapper>
      </RightSide>
        </Container>
      </div>
    </Wrapper>
  )
};

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 100px;
`;

const Container = styled.div`
  justify-content: center;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0px 30px;
`;

const Title = styled.h1`
  text-align: center;
`;

const Subtitle = styled.p`
  text-align: center;
  margin: 0 20px;
  @media (max-width: 860px) {
    max-width: 100%;
  }
`;

const LeftSide = styled.div`
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const Item = styled.div`
  display: flex;
  margin: 0 0 50px 0;
`;

const Fitur = styled.div`
  margin: 0 20px;
`;

const RightSide = styled.div`
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  width: 400px;
  height: auto;
  @media (max-width: 560px) {
    width: 60%;
    height: auto;
  }
`;

export default Feature;